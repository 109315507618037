<template>
  <header class="text-white bg-blue-dark">
    <div
      class="container mx-auto px-4 md:px-0 flex justify-between items-center py-8 lg:py-12"
    >
      <div class="mb-4 text-3xl md:text-4xl">
        <h1 data-cy="reviewers-title">{{ t('reviewers') }}</h1>
      </div>
      <SchoolTestSelect class="flex flex-col" />
    </div>
  </header>
  <main class="container mx-auto">
    <div v-if="!isTestSelected" class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20">
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="select-combination-title">
          {{ t('selectCombinationTitle') }}
        </h2>
        <template #description>
          <p>{{ t('selectCombinationSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
    <div
      v-else-if="!areResultsAvailable"
      class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20"
    >
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="results-not-available-title">
          {{ t('resultsComingSoonTitle') }}
        </h2>
        <template #description>
          <p>
            {{
              t('resultsComingSoonSubtitle', {
                availabilityDate: formattedResultsAvailableAt
              })
            }}
          </p>
        </template>
      </ChartMessage>
    </div>
    <div
      v-else-if="!programHasAccess"
      class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20"
    >
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="program-without-access-title">
          {{ t('resultsProgramHasNoAccessTitle') }}
        </h2>
        <template #description>
          <p>{{ t('resultsProgramHasNoAccessSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
    <div v-else-if="!userHasAccess" class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20">
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="user-without-access-title">
          {{ t('resultsUserHasNoAccessTitle') }}
        </h2>
        <template #description>
          <p>{{ t('resultsUserHasNoAccessSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
    <div v-else-if="loadingData" class="mt-24 w-full flex justify-center">
      <a-spinner />
    </div>
    <div v-else class="mt-24">
      <div class="w-1/3 mt-4">
        <SearchInput
          v-model="searchString"
          label-sr-only
          :label="t('searchReviewerLabel')"
          data-cy="search-input"
        />
      </div>
      <div class="overflow-x-auto mt-12">
        <a-table
          :columns="[
            { name: 'name', label: t('reviewerName') },
            {
              name: 'email',
              label: t('reviewerEmail')
            },
            { name: 'ratedSubmissions' },
            { name: 'pendingSubmissions' },
            { name: 'assignedSubmissions' },
            {
              name: 'actions',
              label: t('actions')
            }
          ]"
          :data="ratersData"
          row-key="id"
          data-cy="reviewers-data-table"
        >
          <template #header-ratedSubmissions>
            <div class="text-center">
              {{ t('applicantsReviewed') }}
            </div>
          </template>
          <template #header-pendingSubmissions>
            <div class="text-center">
              {{ t('applicantsWaitingReview') }}
            </div>
          </template>
          <template #header-assignedSubmissions>
            <div class="text-center">
              {{ t('applicantsAssigned') }}
            </div>
          </template>
          <template #cell-name="{ row }">
            {{ row.rater.profile?.name }}
          </template>
          <template #cell-email="{ row }">
            {{ row.rater.emails[0].address }}
          </template>
          <template #cell-ratedSubmissions="{ row }">
            <div class="text-center" data-cy="rated-submissions">
              {{ row.stats.ratedSubmissions }}
            </div>
          </template>
          <template #cell-pendingSubmissions="{ row }">
            <div class="text-center" data-cy="pending-submissions">
              {{ row.stats.pendingSubmissions }}
            </div>
          </template>
          <template #cell-assignedSubmissions="{ row }">
            <div class="text-center" data-cy="assigned-submissions">
              {{ row.stats.assignedSubmissions }}
            </div>
          </template>

          <template #cell-actions="{ row }">
            <router-link
              :to="{
                name: 'program.assignApplicants',
                params: { raterId: row.rater.id }
              }"
              class="link"
              @click="
                logEvent({
                  eventName: 'SS_ASSIGN_REVIEWERS',
                  userProperties: {
                    userID: auth.user?.id
                  },
                  eventProperties: {
                    reviewerUserId: row.rater.id
                  }
                })
              "
            >
              {{ t('assignApplicants') }}
            </router-link>
          </template>
        </a-table>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { ref, computed, defineComponent, onMounted, watch } from 'vue';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import { authStore } from '@/store/auth';
import { initAmplitude, logEvent } from '@/services/amplitude';

import ChartMessage from '@/components/program/ChartMessage.vue';
import SearchInput from '@/components/common/SearchInput.vue';
import SchoolTestSelect from '@/components/program/SchoolTestSelect.vue';
import { debounceRef } from '@/services/util';

export default defineComponent({
  name: 'ProgramReviewers',
  components: {
    ChartMessage,
    SearchInput,
    SchoolTestSelect
  },
  setup() {
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const stats = computed(() => program.stats);
    const userHasAccess = computed(() => program.userHasAccess);

    const searchString = debounceRef(ref<string | null>(null), 400);

    watch(searchString, newQuery => {
      if (newQuery !== '') {
        logEvent({
          eventName: 'SS_SEARCHED_REVIEWER',
          userProperties: {
            userID: auth.user?.id
          },
          eventProperties: { searchValue: newQuery ?? undefined }
        });
      }
    });

    const filteredRaters = computed(() => {
      return searchString.value === null
        ? program.ratersWithPermissions
        : program.ratersWithPermissions.filter(
            r =>
              searchString.value === null ||
              r.profile?.name.toLowerCase().includes(searchString.value.toLowerCase()) ||
              r.emails[0].address.toLowerCase().includes(searchString.value.toLowerCase())
          ) ?? [];
    });

    const ratersData = computed(() => {
      return filteredRaters.value.length === 0
        ? []
        : filteredRaters.value.map(r => {
            return {
              rater: r,
              stats: stats.value?.find(s => s.raterId === r.id)
            };
          });
    });

    const isTestSelected = computed(
      () => program.currentSchool !== undefined && program.currentTest !== undefined
    );

    const areResultsAvailable = computed(() => program.areResultsAvailable);
    const formattedResultsAvailableAt = computed(
      () => program.formattedResultsAvailableAt
    );

    const programHasAccess = computed(() => program.programHasAccess);

    const loadingData = computed(
      () => program.loadSubmissions.isRunning || program.loadRaters.isRunning
    );

    onMounted(() => {
      initAmplitude('program', auth.user?.id);
    });

    return {
      t: useI18n().t,
      auth,
      logEvent,
      isTestSelected,
      areResultsAvailable,
      formattedResultsAvailableAt,
      programHasAccess,
      userHasAccess,
      searchString,
      ratersData,
      loadingData
    };
  }
});
</script>

<i18n>
{
  "en": {
    "actions": "Actions",
    "applicantsAssigned": "Applicants Assigned",
    "applicantsReviewed": "Applicants Reviewed",
    "applicantsWaitingReview": "Applicants Waiting for Review",
    "assignApplicants": "Assign Applicants",
    "resultsComingSoonSubtitle": "Your applicants' Snapshot video responses will be available at {availabilityDate}",
    "resultsComingSoonTitle": "Coming soon!",
    "resultsProgramHasNoAccessSubtitle": "We cannot find any results based on the combination. The selected program did not opt-in to use the selected Snapshot test. Please try a different combination.",
    "resultsProgramHasNoAccessTitle": "No data available to display",
    "resultsUserHasNoAccessSubtitle": "You do not have access to Snapshot results for the selected program and test combination, please try a different combination or contact your administrator for permission.",
    "resultsUserHasNoAccessTitle": "No access to results",
    "reviewerEmail": "Reviewer Email",
    "reviewerName": "Reviewer Name",
    "reviewers": "Reviewers",
    "searchReviewerLabel": "Search by reviewer name, email",
    "selectCombinationSubtitle": "Before we can display the results, please select a program and admissions cycle from the above dropdown.",
    "selectCombinationTitle": "Please select a combination from the above menus"
  },
  "fr": {
    "actions": "Actions",
    "applicantsAssigned": "Candidats attribués",
    "applicantsReviewed": "Candidats évalués",
    "applicantsWaitingReview": "Candidats en attente d'évaluation",
    "assignApplicants": "Attribuer des candidats",
    "resultsComingSoonSubtitle": "Les réponses vidéos Snapshot de vos candidat(e)s seront disponibles le {availabilityDate}",
    "resultsComingSoonTitle": "Bientôt disponible !",
    "resultsProgramHasNoAccessSubtitle": "Nous n'avons pas trouvé de résultats correspondant à cette combination. Le programme sélectionné n'a pas opté pour l'utilisation de l'entrevue Snapshot sélectionnée. Veuillez essayer une combinaison différente.",
    "resultsProgramHasNoAccessTitle": "Pas de données disponibles",
    "resultsUserHasNoAccessSubtitle": "Vous n'avez pas accès aux résutlats Snapshot pour la combinaison de programmes et d'examens sélectionnées. Veuillez essayer une combinaison différente ou contacter votre administrateur pour une autorisation.",
    "resultsUserHasNoAccessTitle": "Aucun accès aux résultats",
    "reviewerEmail": "Courriel de l'examinateur",
    "reviewerName": "Nom de l'examinateur",
    "reviewers": "Examinateurs",
    "searchReviewerLabel": "Chercher par nom ou courriel de l'examinateur",
    "selectCombinationSubtitle": "Avant de pouvoir afficher des résultats, veuillez sélectionner un programme et une période d'admission dans le menu déroulant ci-dessus.",
    "selectCombinationTitle": "Veuillez sélectionner une combinaison d'options dans les menus ci-dessus."
  }
}
</i18n>
