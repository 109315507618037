export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "applicantsAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants Assigned"])},
        "applicantsReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants Reviewed"])},
        "applicantsWaitingReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants Waiting for Review"])},
        "assignApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Applicants"])},
        "resultsComingSoonSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your applicants' Snapshot video responses will be available at ", _interpolate(_named("availabilityDate"))])},
        "resultsComingSoonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon!"])},
        "resultsProgramHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find any results based on the combination. The selected program did not opt-in to use the selected Snapshot test. Please try a different combination."])},
        "resultsProgramHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available to display"])},
        "resultsUserHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have access to Snapshot results for the selected program and test combination, please try a different combination or contact your administrator for permission."])},
        "resultsUserHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access to results"])},
        "reviewerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Email"])},
        "reviewerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer Name"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])},
        "searchReviewerLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by reviewer name, email"])},
        "selectCombinationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we can display the results, please select a program and admissions cycle from the above dropdown."])},
        "selectCombinationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a combination from the above menus"])}
      },
      "fr": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "applicantsAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats attribués"])},
        "applicantsReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats évalués"])},
        "applicantsWaitingReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats en attente d'évaluation"])},
        "assignApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer des candidats"])},
        "resultsComingSoonSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les réponses vidéos Snapshot de vos candidat(e)s seront disponibles le ", _interpolate(_named("availabilityDate"))])},
        "resultsComingSoonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible !"])},
        "resultsProgramHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas trouvé de résultats correspondant à cette combination. Le programme sélectionné n'a pas opté pour l'utilisation de l'entrevue Snapshot sélectionnée. Veuillez essayer une combinaison différente."])},
        "resultsProgramHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données disponibles"])},
        "resultsUserHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accès aux résutlats Snapshot pour la combinaison de programmes et d'examens sélectionnées. Veuillez essayer une combinaison différente ou contacter votre administrateur pour une autorisation."])},
        "resultsUserHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun accès aux résultats"])},
        "reviewerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de l'examinateur"])},
        "reviewerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'examinateur"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateurs"])},
        "searchReviewerLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher par nom ou courriel de l'examinateur"])},
        "selectCombinationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de pouvoir afficher des résultats, veuillez sélectionner un programme et une période d'admission dans le menu déroulant ci-dessus."])},
        "selectCombinationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une combinaison d'options dans les menus ci-dessus."])}
      }
    }
  })
}
