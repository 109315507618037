
import { ref, computed, defineComponent, onMounted, watch } from 'vue';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import { authStore } from '@/store/auth';
import { initAmplitude, logEvent } from '@/services/amplitude';

import ChartMessage from '@/components/program/ChartMessage.vue';
import SearchInput from '@/components/common/SearchInput.vue';
import SchoolTestSelect from '@/components/program/SchoolTestSelect.vue';
import { debounceRef } from '@/services/util';

export default defineComponent({
  name: 'ProgramReviewers',
  components: {
    ChartMessage,
    SearchInput,
    SchoolTestSelect
  },
  setup() {
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const stats = computed(() => program.stats);
    const userHasAccess = computed(() => program.userHasAccess);

    const searchString = debounceRef(ref<string | null>(null), 400);

    watch(searchString, newQuery => {
      if (newQuery !== '') {
        logEvent({
          eventName: 'SS_SEARCHED_REVIEWER',
          userProperties: {
            userID: auth.user?.id
          },
          eventProperties: { searchValue: newQuery ?? undefined }
        });
      }
    });

    const filteredRaters = computed(() => {
      return searchString.value === null
        ? program.ratersWithPermissions
        : program.ratersWithPermissions.filter(
            r =>
              searchString.value === null ||
              r.profile?.name.toLowerCase().includes(searchString.value.toLowerCase()) ||
              r.emails[0].address.toLowerCase().includes(searchString.value.toLowerCase())
          ) ?? [];
    });

    const ratersData = computed(() => {
      return filteredRaters.value.length === 0
        ? []
        : filteredRaters.value.map(r => {
            return {
              rater: r,
              stats: stats.value?.find(s => s.raterId === r.id)
            };
          });
    });

    const isTestSelected = computed(
      () => program.currentSchool !== undefined && program.currentTest !== undefined
    );

    const areResultsAvailable = computed(() => program.areResultsAvailable);
    const formattedResultsAvailableAt = computed(
      () => program.formattedResultsAvailableAt
    );

    const programHasAccess = computed(() => program.programHasAccess);

    const loadingData = computed(
      () => program.loadSubmissions.isRunning || program.loadRaters.isRunning
    );

    onMounted(() => {
      initAmplitude('program', auth.user?.id);
    });

    return {
      t: useI18n().t,
      auth,
      logEvent,
      isTestSelected,
      areResultsAvailable,
      formattedResultsAvailableAt,
      programHasAccess,
      userHasAccess,
      searchString,
      ratersData,
      loadingData
    };
  }
});
